/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var $postTitle = $('.breadcrumb > span:last-child > span').text();
        if($postTitle.length > 25){
        var shortText = jQuery.trim($postTitle).substring(0, 25).split(" ").slice(0, -1).join(" ") + "...";
          $('.breadcrumb > span > span').text(shortText);
        } 

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired


        //MOBILE NAV
        $('.btn-tab-mobile-menu').click(function(e){
            e.preventDefault();
            $(this).toggleClass('active');
            $('.menu-main-nav-container').toggleClass('active');
        });


        //MASTHEAD ARROW BUTTON
        $('.masthead-arrow-down').click(function(e){
        	e.preventDefault();

        	introOffset = $('#introduction').offset();
        	TweenLite.to(window, 1, {scrollTo:{y:introOffset.top-90}, ease:Power2.easeInOut});
        });


        $('a[href="#contact"]').click(function(e){
        	e.preventDefault();

        	var footerOffset = $('footer').offset();
        	TweenLite.to(window,1,{scrollTo:{y:footerOffset.top}, ease:Power2.easeInOut});

        	$('.btn-tab-mobile-menu').removeClass('active');
        	$('.menu-main-nav-container').removeClass('active');
        })

        //TESTIMONIALS SLIDER
        //get number of testimonials on page
        var testimonialsCount = $('.testimonials-carousel .testimonial').length;

        //if there is more than one testimonial, set up owl carousel
        if(testimonialsCount > 1){
          $(".testimonials-carousel").owlCarousel({
            //animateOut: 'slideOutLeft',
            //animateIn: 'slideInRight',
           // smartSpeed:450,
            items: 1,
            nav: true,
            navSpeed: 1500,
            dots: false,
            loop: true,
            autoHeight: true,
            autoplay: true,
            autoplayTimeout: 7000,
            autoplaySpeed: 1500,
            autoPlayHoverPause: true,
            navText: []
          });
        }

        $('.two-column-image-and-slideshow .slideshow-carousel').owlCarousel({
        	items:1,
        	nav:false,
        	dots:true,
        	loop:true,
        	autoHeight:false,
          autoplay:true,
          autoplayTimeout: 6000,
          autoplayHoverPause:true
        })   
           

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
       
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

         	$('.masthead').css({'height': $(window).height() + 'px'});
         	$('header').attr('data-offset-top', $(window).innerHeight() - 90);

         	$(window).resize(function(){
         		$('.masthead').css({'height': $(window).height() + 'px'});
         		$('header').attr('data-offset-top', $(window).innerHeight() - 90);
         	})
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
